import '../../styles/global/index.scss';

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import SiteFooter from '../SiteFooter';
import SiteHeader from '../SiteHeader';

function Layout({children}) {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <>
      <SiteHeader siteTitle={site.siteMetadata.title} />
      <main>
        {children}
      </main>
      <SiteFooter />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
