import './ContactInfoHome.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from '../../utils';
import CompanyAddressHome from '../CompanyAddressHome';
import Logo from '../../icons/logo.svg';
import Logo2 from '../../icons/logo2.svg';
import SocialMedia from '../SocialMedia';
import TeleList from '../TeleList';

function ContactInfo({appearance="simple", className}) {
  return (
    <section className={classNames('contact-info', className)}>
      <div>
        {appearance === 'full' && <Logo className="contact-info__logo" />}<br />
        {appearance === 'full' && <Logo2 className="contact-info__logo" />}
        <CompanyAddressHome
          includeName={appearance === 'simple'}
          className="contact-info__address"
        />
        <TeleList
          appearance={appearance === 'full' ? 'inline' : null}
          className="contact-info__numbers"
        />
        <SocialMedia
          alignment={appearance==='full' ? 'center' : null}
          size={appearance==='full' ? 'small' : null}
        />
      </div>
    </section>
  );
}

ContactInfo.propTypes = {
  appearance: PropTypes.oneOf(['simple', 'full'])
}

export default ContactInfo;
