import './SocialMedia.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LinkedInIcon from '../../icons/linkedin-icon.svg';

function SocialMedia({alignment="left", size="large"}) {
  return (
    <ul className={`social-media social-media--${alignment}`}>
      <li className="social-media-item">
        <a className={`social-media__icon-link social-media__icon-link--${size}`} href="https://www.linkedin.com/company/tristarsystems" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
        </a>
      </li>
    </ul>
  );
}

SocialMedia.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center']),
  size: PropTypes.oneOf(['small', 'large'])
}

export default SocialMedia;
