import './IconButton.scss';

import React from 'react';

function IconButton({className, children, ...rest}) {
  return (
    <button className={`icon-button ${className}`} {...rest}><span className="icon-button__icon">{children}</span></button>
  );
}

export default IconButton;
