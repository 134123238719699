import './Button.scss';

import PropTypes from 'prop-types';
import React from 'react';

function Button({className, children, appearance, elementType='button', ...rest}) {
  let mergedClassNames = 'button';

  if(appearance) {
    mergedClassNames = `${mergedClassNames} button--${appearance}`;
  }

  if(className) {
    mergedClassNames = `${mergedClassNames} ${className}`;
  }

  if(elementType === 'link') {
    return <a className={mergedClassNames} {...rest}>{children}</a>
  }

  return (
    <button className={mergedClassNames} {...rest}>{children}</button>
  );
}

Button.propTypes = {
  appearance: PropTypes.oneOf(['primary']),
  elementType: PropTypes.oneOf(['link', 'button'])
}

export default Button;
