import './TeleList.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { classNames } from '../../utils';

function TeleList({appearance="block", className}) {
  return (
    <ul className={classNames('tele-list', className)}>
      <li className={classNames('tele-item', appearance !== 'block' ? `tele-item--${appearance}` : '')}>
          <span className="tele-item__label">phone:</span>
          <a href="tel:3145764022">314.576.4022</a>
      </li>
      <li className={classNames('tele-item', appearance !== 'block' ? `tele-item--${appearance}` : '')}>
          <span className="tele-item__label">toll free:</span>
          <a href="tel:8007270182">800.727.0182</a>
      </li>
      <li className={classNames('tele-item', appearance !== 'block' ? `tele-item--${appearance}` : '')}>
          <span className="tele-item__label">fax:</span> 314.985.0277
      </li>
    </ul>
  );
}

TeleList.propTypes = {
  appearance: PropTypes.oneOf(['block', 'inline'])
}

export default TeleList;
