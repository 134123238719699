import './SiteFooter.scss';

import { Link } from 'gatsby';
import React from 'react';

import ContactInfoHome from '../ContactInfoHome';
import Newsletter from '../Newsletter';

function SiteFooter() {
  return (
    <footer>
      <Newsletter />
      <ContactInfoHome className="site-footer__contact-info" appearance="full" />

      <div className="site-footer-wrapper">
        <div className="site-footer">
          <nav className="site-footer-nav">
            <Link to="/" className="site-footer-nav__link">Home</Link>
            <Link to="/services" className="site-footer-nav__link">Services</Link>
            <Link to="/about" className="site-footer-nav__link">About</Link>
            <Link to="/resources" className="site-footer-nav__link">Resources</Link>
            <Link to="/contact" className="site-footer-nav__link">Contact</Link>
          </nav>
          <small className="site-footer__copyright">&copy; {new Date().getFullYear()} Tri-Star Systems</small>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
