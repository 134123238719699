import './CompanyAddressHome.scss';

import React from 'react';

import { classNames } from '../../utils';

function CompanyAddressHome({includeName, className}) {
  return (
    <address className={classNames('company-address', className)}>
      {includeName ? <strong>Tri-Star Systems<br /></strong> : null}
      16401 Swingley Ridge Road Suite 250<br />
    Chesterfield, MO 63017<br /><br />
  <strong>Office Hours:</strong><br />
  Monday - Friday<br />
  7:00 a.m. - 5:00 p.m. Central Time
    </address>
  );
}

export default CompanyAddressHome;
