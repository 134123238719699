import './SiteNav.scss';

import { Link } from 'gatsby';
import React from 'react';

import IconButton from '../IconButton';
import MenuIcon from '../../icons/menu-icon.svg';
import XIcon from '../../icons/x-icon.svg';

class SiteNav extends React.PureComponent {
  state = {
    mobileMenuIsVisible: false
  }

  toggleMobileMenu = () => {
    this.setState((state) => ({mobileMenuIsVisible: !state.mobileMenuIsVisible}))
  }
  render() {
    const {mobileMenuIsVisible} = this.state;
    return (
      <div className="site-nav-container">
        <div className={`site-nav-mobile ${mobileMenuIsVisible ? 'site-nav-mobile--visible' : ''}`}>
          <nav className="site-nav">
            <Link to="/services" partiallyActive={true} className="site-nav__link" activeClassName="site-nav__link--active">Services</Link>
            <Link to="/about" className="site-nav__link" activeClassName="site-nav__link--active">About</Link>
            <Link to="/resources" className="site-nav__link" activeClassName="site-nav__link--active">Resources</Link>
            <Link to="/contact" className="site-nav__link">Contact Us</Link>
            <a href="https://secure.tri-starsystems.com/ee/login.asp" className="site-nav__button">Participant Login</a>
            <a href="https://secure.tri-starsystems.com/hr1/" className="site-nav__button">HR Login</a>
            <div className="mini-nav">
              <a href="tel:800-727-0182" className="mini-nav__link">Give us a call <strong>(800) 727-0182</strong></a>
            </div>
            <IconButton onClick={this.toggleMobileMenu} className="site-nav__mobile-btn site-nav__mobile-btn--dismiss"><XIcon /></IconButton>
          </nav>
        </div>
        <IconButton onClick={this.toggleMobileMenu} className="site-nav__mobile-btn"><MenuIcon /></IconButton>
      </div>
    );
  }
}

export default SiteNav;
