import './Newsletter.scss';

import React from 'react';

import Button from '../Button';
import TextField from '../TextField';

class Newsletter extends React.PureComponent {
  state = {
    status: ''
  }

  submitForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }

  render() {
    const { status } = this.state;
    return (
      <section className="newsletter">
        <div className="newsletter-wrapper">
          <form
            className="newsletter-form"
            onSubmit={this.submitForm}
            action="https://formspree.io/mrrvkqpm"
            method="POST"
          >
            <h1 className="newsletter-heading">Subscribe <small>to our newsletter!</small></h1>
            <div className="newsletter__input-group">
              <TextField className="newsletter__input" name="First/Last Name" type="text" placeholder="First/Last Name" required />
              <TextField className="newsletter__input" name="Email" type="email" placeholder="Email Address" required />
              <Button className="newsletter__submit" type="submit">SIGN UP</Button>
            </div>
          </form>
          {status === "SUCCESS" && <p className="newsletter-message newsletter-message--success">Thank you for subscribing!</p>}
          {status === "ERROR" && <p className="newsletter-message newsletter-message--error">There was an error.</p>}
        </div>
      </section>
    );
  }
}

export default Newsletter;
