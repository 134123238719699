import './SiteHeader.scss';

import { Link } from 'gatsby';
import React from 'react';

import Logo from '../../icons/logo.svg';
import SiteNav from '../SiteNav';

function SiteHeader() {
  return (
    <header className="site-header-container">
      <div className="site-header">
        <Link to="/" className="site-logo">
          <Logo />
          <span className="site-logo__tagline">Innovative Employee Benefit Administration Solutions</span>
        </Link>
        <SiteNav />
      </div>
    </header>
  )
}

export default SiteHeader;
