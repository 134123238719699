import './TextField.scss';

import React from 'react';

import { classNames } from '../../utils';

function TextField({className, type='text', ...rest}) {
  return (
    <input className={classNames('text-field', className)} type={type} {...rest} />
  );
}

export default TextField;
